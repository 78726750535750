import toast from 'react-hot-toast'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { DataFile } from 'shared/types/datafile-type'
import { RequestMethodsEnum } from './use-api-wrapper-with-error-validation/types'

interface CropOptionsType {
  x: number
  y: number
  width: number
  height: number
}

export function useUploadImage() {
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.postForm,
    DataFile
  >({
    method: RequestMethodsEnum.postForm,
    badRequestHandler: null,
  })

  return {
    uploadImage: ({
      file,
      source,
      type,
      cropOptions,
      formDataOptions = {},
    }: {
      file: File
      source?: string
      type?: string
      cropOptions?: CropOptionsType
      formDataOptions?: Record<string, any>
    }) => {
      const formData: Record<string, any> = {
        'image[file][file]': file,
        ...formDataOptions,
      }

      if (source) {
        formData['source'] = source
      }

      if (cropOptions) {
        formData['image[file][x]'] = cropOptions.x
        formData['image[file][y]'] = cropOptions.y
        formData['image[file][w]'] = cropOptions.width
        formData['image[file][h]'] = cropOptions.height
      }
      return fetcher(`/api/dashboard/image/upload${type ? `/${type}` : ''}`, formData)
    },
    isFetching,
  }
}
